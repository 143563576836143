import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    redirect: "/Home",
  },
  {
    path: "/Home",
    name: "Home",
    meta: {
      title: "首页",
    },
    component: Home,
  },
  {
    path: "/Details",
    name: "Details",
    meta: {
      title: "详情页",
    },
    component: () => import("@/views/Details.vue"),
  },
  {
    path: "/Simulator",
    name: "Simulator",
    meta: {
      title: "模拟器页",
    },
    component: () => import("@/views/Simulator.vue"),
  },
  {
    path: "/Discount",
    name: "Discount",
    meta: {
      title: "优惠页",
    },
    component: () => import("@/views/Discount.vue"),
  },
  {
    path: "/Utils",
    name: "Utils",
    meta: {
      title: "工具页",
    },
    component: () => import("@/views/Utils.vue"),
  },
  {
    path: "/Domain",
    name: "Domain",
    meta: {
      title: "域名页",
    },

    component: () => import("@/views/Domain.vue"),
  },
  {
    path: "/Help",
    name: "Help",
    meta: {
      title: "网站帮助",
    },

    component: () => import("@/views/Help/Help.vue"),
  },
  {
    path: "/AppleId",
    name: "AppleId",
    component: () => import("@/views/Help/AppleId.vue"),
    meta: { title: "苹果海外ID" },
  },
  {
    path: "/HelpDetails",
    name: "HelpDetails",
    component: () => import("@/views/Help/HelpDetails.vue"),
    meta: { title: "访问教程" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  // history: createWebHashHistory(),
  routes,
});

export default router;
