import request from "@/utils/request";
import axios from "axios";

export const getUserIp = () =>
  request({
    url: "/User/createUser",
    method: "GET",
  });
//获取网站配置信息
export const getWebConfig = () =>
  request({
    url: "/Theme/getData",
    method: "GET",
  });
//获取网站底部配置信息
export const getFooterList = () =>
  request({
    url: "/Bottom/getBottom",
    method: "GET",
  });
//获取所有一级分类
export const getClassV1List = () =>
  request({
    url: "/ClassV1/getClass",
    method: "GET",
  });
//获取所有一级分类详情
export const getClassV1ListDetailAll = () =>
  request({
    url: "/ClassV1/getClassDetailAll",
    method: "GET",
  });

//获取二级分类
export const getClassV2List = () =>
  request({
    url: "/ClassV2/getClassV2",
    method: "GET",
  });

//获取二级分类详情信息
export const getClassV2ListDetail = (params) =>
  request({
    url: "/ClassV2/getClassDetailV2",
    method: "GET",
    params,
  });

//获取模拟器列表
export const getSimulatorList = (params) =>
  request({
    url: "/Simulator/getSimulatorDetail",
    method: "GET",
    params,
  });

//模拟器模糊搜索
export const searchSimulatorList = (params) =>
  request({
    url: "/Simulator/searchSimulator",
    method: "GET",
    params,
  });

//获取模拟器头部列表
export const getSimulatorTopList = () =>
  request({
    url: "/Simulator/getSimulatorTop",
    method: "GET",
  });

//发送错误日志
export const sendLog = (data) =>
  request({
    url: "/Log/createLog",
    method: "POST",
    data,
  });
