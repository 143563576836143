import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Lazyload } from "vant";
import { sendLog } from "./api";
import "@/styles/index.scss";
const app = createApp(App);

// 记录用户行为
app.config.globalProperties.$handelUserLog = (
  type,
  name,
  info,
  component,
  logContentType
) => {
  try {
    sendLog({
      logType: type,
      logName: name,
      logComponents: component,
      logInfo: info,
      logContentType,
    });
  } catch (error) {
    console.log("记录失败", error);
  }
};

app.config.errorHandler = (err, instance, info) => {
  // 处理错误
  sendLog({
    logType: "error",
    logName: String(err),
    logComponents: instance.$route.name,
    logInfo: info,
    logContentTypeName: "error",
    logContentTypId: "1",
  }).then((res) => {});
};

//限制用户打开控制台
// if (process.env.NODE_ENV === "production") {
//   function detectDevTools() {
//     const start = new Date().getTime();
//     eval("debugger");
//     const end = new Date().getTime();

//     if (end - start > 100) {
//       window.location.href = "about:blank";
//     }
//   }

//   window.addEventListener("resize", () => {
//     setTimeout(() => {
//       detectDevTools();
//     }, 200);
//   });
// }

app.use(store).use(router).use(Lazyload).mount("#app");
